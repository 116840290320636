import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import TodoList from "../components/TodoList";

// redux state
import { useSelector, useDispatch } from "react-redux";
import { currentCalendar, getCalendar } from "../slices/calendarSlice";

import loader from "../loading.svg";

const localizer = momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar);

const myEventsList = [
  // {
  //   title: "Monkey Business",
  //   start: new Date("2024-05-27T21:00:00+03:00"), // April 9th, 2024 at 10:00 AM
  //   end: new Date("2024-05-27T21:30:00+03:00"), // April 9th, 2024 at 11:00 AM
  // },
  // {
  //   title: "Further Monkey Business",
  //   start: new Date(2024, 3, 9, 9, 30), // April 9th, 2024 at 10:00 AM
  //   end: new Date(2024, 3, 9, 10, 0), // April 9th, 2024 at 11:00 AM
  // },
  // {
  //   title: "Meeting",
  //   start: new Date(2024, 3, 9, 10, 0), // April 9th, 2024 at 10:00 AM
  //   end: new Date(2024, 3, 9, 11, 0), // April 9th, 2024 at 11:00 AM
  // },
  // {
  //   title: "Doctor's Appointment",
  //   start: new Date(2024, 3, 12, 14, 30), // April 12th, 2024 at 2:30 PM
  //   end: new Date(2024, 3, 12, 15, 30), // April 12th, 2024 at 3:30 PM
  // },
  // {
  //   title: "Birthday Party",
  //   start: new Date(2024, 3, 16, 19, 0), // April 16th, 2024 at 7:00 PM
  //   end: new Date(2024, 3, 17, 1, 0), // April 17th, 2024 at 1:00 AM (next day)
  // },
];

const BookMeeting = () => {
  const dispatch = useDispatch();
  const calendar = useSelector(currentCalendar);
  const calendarStatus = useSelector((state) => state.calendar.status);

  const { agent, property } = useParams();

  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [smallPopup, setSmallPopup] = useState(false);
  const [isLoading, setIsLoading] = useState("block");
  const [popupText, setPopupText] = useState("");
  const [slotData, setSlotData] = useState("");

  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [timeStart, setTimeStart] = useState("");
  const [duration, setDuration] = useState("30mins");
  const [description, setDescription] = useState("");
  const [isPopup, setIsPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    console.log("effect outer");
    let getDetails = async () => {
      console.log("effect inner");
      try {
        const response = await axios.get(
          `/api/client/create-event/${agent}/${property}`,
          {
            withCredentials: true,
          }
        );
        console.log(response);
        setPopupTitle(response.data);
        setTitle(response.data.property);
      } catch (e) {
        console.log("client calendar error: ", e);
        setError(true);
      }
    };
    getDetails();
  }, []);

  console.log("events: ", myEventsList, events);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSmallPopup(true);
    let eventStart = timeStart.toString();
    let eventEnd = "";
    if (duration == "30mins") {
      let originalDate = new Date(timeStart);
      originalDate.setMinutes(originalDate.getMinutes() + 30);
      eventEnd = originalDate.toString();
    } else {
      let originalDate = new Date(timeStart);
      originalDate.setHours(originalDate.getHours() + 1);
      eventEnd = originalDate.toString();
    }
    let data = {
      agent: agent,
      property: property,
      title: title,
      name: name,
      email: email,
      start: eventStart,
      end: eventEnd,
      description: description,
    };
    console.log("event data:", data);
    try {
      const response = await axios.post("/api/client/create-event", data, {
        withCredentials: true,
      });
      console.log("success: ", response);
      setIsLoading("none");
      setPopupText("Meeting scheduled successfully");
      dispatch(getCalendar());
      setTimeout(() => {
        setSmallPopup(false);
        setIsPopup(false);
        navigate("/");
      }, 3000);
    } catch (error) {
      console.log("error: ", error);
      setPopupText("Error scheduling meeting");
      setIsLoading("none");
      setTimeout(() => {
        setSmallPopup(false);
        setIsPopup(false);
      }, 3000);
    }
  };

  return (
    <div
      className="flex"
      style={{
        height: "100vh",
        background: "black",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {error ? (
        <div
          className="flex"
          style={{
            flexDirection: "column",
            height: "100vh",
            background: "black",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h4
            style={{
              color: "red",
              textAlign: "center",
              paddingBottom: ".5rem",
            }}
          >
            Invalid calendar link
          </h4>
          <a
            href="/"
            style={{
              color: "var(--color__calendar-text)",
              textAlign: "center",
              textDecoration: "none",
            }}
          >
            &larr; Back home
          </a>
        </div>
      ) : (
        <div className="welcome__container" style={{ padding: "20px" }}>
          <h1
            style={{
              color: "var(--color__calendar-text)",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            Schedule a Meeting with {popupTitle && popupTitle.agent}
          </h1>
          <h3
            style={{
              color: "var(--color__calendar-text)",
              textAlign: "center",
              paddingBottom: "50px",
            }}
          >
            for {popupTitle && popupTitle.property}
          </h3>
          <div className="flex">
            <div style={{ width: "100%" }}>
              <DnDCalendar
                selectable={true}
                onSelectEvent={(data) => {
                  console.log("onSelectEvent", data);
                }}
                onSelectSlot={(slot) => {
                  console.log("onSelectSlot", slot);
                  setSlotData(slot);
                  setIsPopup(true);
                  setTimeStart(slot.start);
                }}
                onSelecting={(day) => {
                  console.log("onSelect", day);
                }}
                localizer={localizer}
                events={events}
                startAccessor="start"
                draggableAccessor={(event) => true}
                endAccessor="end"
                style={{ height: 500 }}
              />
              {isPopup && (
                <div className="leads-popup leads-popup--delete add-event-container">
                  <div className="leads-popup__container flex justify-content-center align-center add-event-popup">
                    <h3 style={{ paddingBottom: "20px" }}>
                      Meeting with {popupTitle && popupTitle.agent}
                      <br />
                      on {new Date(slotData.start).toDateString()} at{" "}
                      {new Date(slotData.start).toLocaleTimeString()}
                    </h3>
                    <form
                      onSubmit={handleSubmit}
                      className="flex justify-content-center align-center add-event-popup"
                    >
                      <input
                        readOnly
                        required
                        type="text"
                        value={title}
                        className="mb-20"
                        name="title"
                      />
                      <input
                        required
                        type="text"
                        placeholder="Your name"
                        className="mb-20"
                        name="title"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <input
                        required
                        type="email"
                        placeholder="Your email"
                        className="mb-20"
                        name="title"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <select
                        required
                        name="list"
                        placeholder="Duration"
                        className="mb-20"
                        onChange={(e) => setDuration(e.target.value)}
                      >
                        <option value="30mins">30mins</option>
                        <option value="1hr">1hr</option>
                      </select>
                      <textarea
                        required
                        placeholder="Add details"
                        defaultValue={""}
                        maxLength="1024"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <div className="flex align-center broad-btns">
                        <button
                          type="submit"
                          className="btn btn--schedule flex align-center"
                          onClick={(e) => {
                            setIsPopup(false);
                            setSlotData("");
                          }}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn--lunch">
                          Save Event
                        </button>
                      </div>
                    </form>
                  </div>
                  {smallPopup && (
                    <div className="leads-popup leads-popup--delete">
                      <div className="leads-popup__container flex justify-content-center align-center">
                        <img
                          src={loader}
                          style={{ height: "150px", display: isLoading }}
                        />
                        <h3
                          style={{
                            color: popupText.includes("success")
                              ? "green"
                              : "red",
                          }}
                        >
                          {popupText}
                        </h3>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookMeeting;
