import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import IconDownload from "../components/svgs/IconDownload";
import axios from "axios";

const Team = () => {
  const [subusers, setSubusers] = useState(false);

  useEffect(() => {
    const getSubusers = async function () {
      try {
        const response = await axios.get("api/subusers", {
          withCredentials: true,
        });
        console.log("sub-users: ", response.data);
        setSubusers(response.data);
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          alert("You're logged out. Please log in again.");
        }
      }
    };
    getSubusers();
  }, []);

  return (
    <main className="flex team-page">
      <Sidebar title="Team" />
      <div className="main-container">
        <Header />
        <div className="team">
          {subusers
            ? subusers.map((agent) => {
                return (
                  <div className="team__row">
                    <div>
                      <p>{agent.displayname}</p>
                      <p>{agent.position}</p>
                    </div>

                    <div>
                      <p>Phone: {agent.phone}</p>
                      <p>Email: {agent.username}</p>
                    </div>

                    <div className="large-no">
                      <p>6</p>
                      <p>Broadcast Generated</p>
                    </div>

                    <div>
                      <p>Schedule Conversion</p>
                      <p>34.6%</p>
                    </div>

                    <div>
                      <p>Broadcast Conversion</p>
                      <p>8.4%</p>
                    </div>

                    <div>
                      <p>Rank</p>
                      <p>#1</p>
                    </div>

                    <div>
                      <IconDownload />
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </main>
  );
};

export default Team;
