import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  user: null,
  status: "idle",
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCurrentUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { fetch_user } = authSlice.actions;

export default authSlice.reducer;

export const currentUser = (state) => state.auth;

export const getCurrentUser = createAsyncThunk("user/fetchUser", async () => {
  const response = await axios.get("/api/current_user");

  console.log("auth slice: ", response);
  return response.data;
});
