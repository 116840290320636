import React from "react";

const Otp = () => {
  return (
    <div className="flex options">
      <a href="#" className="page__logo">
        <img src="assets/images/logo-medium.svg" alt srcSet />
      </a>
      <div className="options__container color-white">
        <a href="#" className="btn-back">
          Back
          <img src="assets/icons/icon-back.svg" alt srcSet />
        </a>
        <h1 className="h1">Whatsapp Business OTP</h1>
        <form className="mt-45 otp-form">
          <input type="number" maxLength={1} className="input input--otp" />
          <input type="number" maxLength={1} className="input input--otp" />
          <input type="number" maxLength={1} className="input input--otp" />
          <input type="number" maxLength={1} className="input input--otp" />
        </form>
        <h2 className="h1 mt-130">Email Verification OTP</h2>
        <form className="mt-45 otp-form">
          <input type="number" maxLength={1} className="input input--otp" />
          <input type="number" maxLength={1} className="input input--otp" />
          <input type="number" maxLength={1} className="input input--otp" />
          <input type="number" maxLength={1} className="input input--otp" />
        </form>
        <a href="#" className="btn-continue">
          Continue
          <img src="assets/icons/icon-border-arrow.svg" alt srcSet />
        </a>
      </div>
    </div>
  );
};

export default Otp;
