import React, { useState } from "react";
import { Link } from "react-router-dom";

const Packages = () => {
  const [activeTab, setActiveTab] = useState("agent");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="main-container" style={{ width: "100%" }}>
      <div className="flex options">
        <a href="#" className="page__logo">
          <img src="assets/images/logo-medium.svg" alt srcSet />
        </a>
        <div className="options__container color-white">
          <Link to="/dashboard" className="btn-back">
            Back
            <img src="assets/icons/icon-back.svg" alt srcSet />
          </Link>
          <div className="packages-switch">
            <button
              className={activeTab === "agent" ? "active" : ""}
              onClick={() => handleTabClick("agent")}
            >
              AGENT
            </button>
            <button
              className={activeTab === "enterprise" ? "active" : ""}
              onClick={() => handleTabClick("enterprise")}
            >
              ENTERPRISE
            </button>
          </div>
          <div
            className={`packages ${
              activeTab === "agent"
                ? "active packages--agent"
                : "packages--agent"
            }`}
          >
            <div className="packages__card">
              <h2>Basic </h2>
              <p>Essential for new agents starting their journey.</p>
              <div className="price">
                $99<sub>.91/mo</sub>
              </div>
              <ul>
                <li>50 Engagements</li>
                <li>Broadcast Options</li>
                <li>Auto Scheduler</li>
                <li>Analytics</li>
                <li>Pipeline</li>
              </ul>
              <Link to="/individual-packages" className="btn btn--started">
                Get Started
              </Link>
            </div>
            <div className="packages__card">
              <h2>Pro </h2>
              <p>
                Ideal for expanding agents needing advanced lead management.
              </p>
              <div className="price">
                $99<sub>.91/mo</sub>
              </div>
              <ul>
                <li>50 Engagements</li>
                <li>Broadcast Options</li>
                <li>Auto Scheduler</li>
                <li>Analytics</li>
                <li>Pipeline</li>
              </ul>
              <Link to="/individual-packages" className="btn btn--started">
                Get Started
              </Link>
            </div>
            <div className="packages__card">
              <h2>Premium</h2>
              <p>
                The top choice for elite agents craving AI-powered lead
                generation.
              </p>
              <div className="price">
                $99<sub>.91/mo</sub>
              </div>
              <ul>
                <li>50 Engagements</li>
                <li>Broadcast Options</li>
                <li>Auto Scheduler</li>
                <li>Analytics</li>
                <li>Pipeline</li>
              </ul>
              <Link to="/individual-packages" className="btn btn--started">
                Get Started
              </Link>
            </div>
          </div>
          <div
            className={`packages ${
              activeTab === "enterprise"
                ? "active packages--enterprise"
                : "packages--enterprise"
            }`}
          >
            <div className="packages__card">
              <h2>Basic1 </h2>
              <p>Essential for new agents starting their journey.</p>
              <div className="price">
                $99<sub>.91/mo</sub>
              </div>
              <ul>
                <li>50 Engagements</li>
                <li>Broadcast Options</li>
                <li>Auto Scheduler</li>
                <li>Analytics</li>
                <li>Pipeline</li>
              </ul>
              <Link to="/individual-packages" className="btn btn--started">
                Get Started
              </Link>
            </div>
            <div className="packages__card">
              <h2>Pro </h2>
              <p>
                Ideal for expanding agents needing advanced lead management.
              </p>
              <div className="price">
                $2359<sub>.9/mo</sub>
              </div>
              <ul>
                <li>50 Engagements</li>
                <li>Broadcast Options</li>
                <li>Auto Scheduler</li>
                <li>Analytics</li>
                <li>Pipeline</li>
              </ul>
              <Link to="/individual-packages" className="btn btn--started">
                Get Started
              </Link>
            </div>
            <div className="packages__card">
              <h2>Premium</h2>
              <p>
                The top choice for elite agencies craving AI-powered lead
                generation.
              </p>
              <div className="price">
                $3399<sub>.4/mo</sub>
              </div>
              <ul>
                <li>50 Engagements</li>
                <li>Broadcast Options</li>
                <li>Auto Scheduler</li>
                <li>Analytics</li>
                <li>Pipeline</li>
              </ul>
              <Link to="/individual-packages" className="btn btn--started">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;
