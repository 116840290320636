const IconDownload = () => {
  return (
   <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-outside-1_5608_6577" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="27" fill="black">
    <rect fill="white" width="24" height="27"/>
    <path d="M0 0H24V26H0V0Z"/>
    </mask>
    <path d="M24 25H0V27H24V25Z" fill="#A7A7A7" mask="url(#path-1-outside-1_5608_6577)"/>
    <path d="M6 9.75L12 16.25L18 9.75" stroke="#E3F4F4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
}

export default IconDownload;