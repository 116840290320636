import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";

const ForgotPassword = () => {
  useEffect(() => {
    document.body.setAttribute("data-theme", "dark");
  }, []);

  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("none");

  const handleSubmit = async function (e) {
    e.preventDefault();
    const response = await axios.post(
      "/api/forgot-password",
      { username },
      {
        withCredentials: true,
      }
    );

    setMessage(response.data.message);

    console.log(response);
  };

  return (
    <div
      className="main-container"
      style={{ width: "100%", paddingBottom: "0" }}
    >
      <div className="flex login-page">
        <img src="assets/images/logo-large.svg" alt="logo large" />
        <form onSubmit={handleSubmit} autoComplete="off">
          <input
            autoComplete="false"
            name="hidden"
            type="text"
            style={{ display: "none" }}
          />
          <h2 className="form-title">Forgot Password?</h2>
          <div className="input-group">
            {message == "fail" ? (
              <p
                style={{ display: "flex", marginBottom: "1rem", color: "red" }}
              >
                User does not exist
              </p>
            ) : message == "success" ? (
              <p
                style={{
                  display: "flex",
                  marginBottom: "1rem",
                  color: "green",
                }}
              >
                Email sent
              </p>
            ) : null}
            <label htmlFor="username">Input your email</label>
            <input
              type="text"
              placeholder="Email"
              className="input user-icon"
              autoComplete="none"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn--full-width mt-14">
            Send Reset Link
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
