import React from "react";
import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <div className="flex welcome">
      <div className="welcome__container">
        <Link to="/login" className="btn-continue">
          Continue
          <img src="assets/icons/icon-border-arrow.svg" alt srcSet />
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
