const IconLight = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2372_104903)">
      <path d="M9.09892 6.66666C8.73917 7.11349 8.54296 7.70527 8.55174 8.31705C8.56052 8.92882 8.7736 9.51268 9.14597 9.94533C9.51834 10.378 10.0209 10.6255 10.5474 10.6357C11.0739 10.6459 11.5833 10.418 11.9679 9.99999C11.9679 10.6593 11.7996 11.3037 11.4844 11.8519C11.1691 12.4001 10.721 12.8273 10.1968 13.0796C9.67258 13.3319 9.09574 13.3979 8.53922 13.2693C7.9827 13.1407 7.4715 12.8232 7.07027 12.357C6.66905 11.8908 6.39581 11.2969 6.28511 10.6503C6.17441 10.0037 6.23122 9.33346 6.44837 8.72438C6.66551 8.11529 7.03323 7.5947 7.50502 7.22842C7.97682 6.86215 8.5315 6.66666 9.09892 6.66666Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.09912 1.66666V3.33332" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.09912 16.6667V18.3333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.00659 4.08334L5.01072 5.25001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.1873 14.75L14.1914 15.9167" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.92676 10H3.36123" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.8369 10H16.2714" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.01072 14.75L4.00659 15.9167" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.1914 4.08334L13.1873 5.25001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_2372_104903">
      <rect width="17.2136" height="20" fill="white" transform="translate(0.492188)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default IconLight;