import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";

const ResetPassword = () => {
  useEffect(() => {
    document.body.setAttribute("data-theme", "dark");
  }, []);

  const token = useParams();
  const navigate = useNavigate();

  console.log("token: ", token);

  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("none");
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async function (e) {
    setLoading(true);
    try {
      console.log("password: ", password);
      e.preventDefault();
      const response = await axios.post(
        `/api/reset-password/${token.token}`,
        { password: password },
        {
          withCredentials: true,
        }
      );

      setMessage(response.data.message);

      console.log(response);
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      console.log("error: ", error.response.data.message);
      setMessage(error.response.data.message);
    }

    setLoading(false);
  };

  return (
    <div
      className="main-container"
      style={{ width: "100%", paddingBottom: "0" }}
    >
      <div className="flex login-page">
        <img src="assets/images/logo-large.svg" alt="logo large" />
        <form onSubmit={handleSubmit} autoComplete="off">
          <input
            autoComplete="false"
            name="hidden"
            type="text"
            style={{ display: "none" }}
          />
          <h2 className="form-title">Set New Password</h2>
          <div className="input-group">
            {message == "expired" ? (
              <p
                style={{ display: "flex", marginBottom: "1rem", color: "red" }}
              >
                Invalid or expired roken
              </p>
            ) : message == "success" ? (
              <p
                style={{
                  display: "flex",
                  marginBottom: "1rem",
                  color: "green",
                }}
              >
                Password reset successfully, redirecting to login page
              </p>
            ) : null}
            <label htmlFor="username">Input new password</label>
            <input
              type="password"
              placeholder="Password"
              className="input user-icon"
              autoComplete="none"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn--full-width mt-14">
            Save New Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
