import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  properties: null,
  status: "idle",
  error: null,
};

export const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getListings.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getListings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.properties = action.payload;
      })
      .addCase(getListings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { fetch_listings } = listingsSlice.actions;

export default listingsSlice.reducer;

export const currentListings = (state) => state.listings;

export const getListings = createAsyncThunk(
  "listings/getListings",
  async () => {
    const response = await axios.get("/api/broadcast");

    console.log("check this out: ", response);
    return response.data;
  }
);
