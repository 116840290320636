const MainLogo = () => {
return (
    <svg
      width={150}
      height={76}
      viewBox="0 0 150 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M4.23912 45.5025C1.41224 42.5156 0 38.8522 0 34.5125C0 30.1681 1.41224 26.4931 4.23912 23.4853C7.07079 20.4728 10.6673 18.9666 15.0287 18.9666C19.0377 18.9666 22.3369 20.1846 24.9264 22.6206V19.822H35.3612V49.1286H25.0511V45.8186H24.9264C22.3369 48.5963 19.0377 49.984 15.0287 49.984C10.6673 49.984 7.07079 48.4917 4.23912 45.5025ZM13.1106 29.5382C11.7535 30.8352 11.0773 32.4925 11.0773 34.5125C11.0773 36.5278 11.7247 38.1735 13.0243 39.4496C14.3214 40.7211 16.031 41.3557 18.1553 41.3557C20.1934 41.3557 21.8717 40.7118 23.1905 39.4217C24.5068 38.127 25.1662 36.4883 25.1662 34.5032C25.1662 32.4902 24.4876 30.8352 23.1329 29.5382C21.7758 28.2434 20.1166 27.5949 18.1553 27.5949C16.1461 27.5949 14.4653 28.2434 13.1106 29.5382Z"
        fill="currentColor"
      />
      <path
        d="M38.1328 49.1296V0H48.558V49.1296H38.1328Z"
        fill="currentColor"
      />
      <path
        d="M53.0945 19.8233V8.91699H63.5197V19.8233H69.8879V27.5405H63.5197V36.8476C63.5197 39.5509 64.3061 40.9015 65.879 40.9015C66.2746 40.9015 66.687 40.8271 67.1162 40.6783C67.5502 40.5249 67.8859 40.3715 68.1232 40.2227L68.4781 39.9903L71.0676 48.163C68.8282 49.3764 66.2746 49.9854 63.4046 49.9854C61.4409 49.9854 59.7529 49.653 58.3407 48.9905C56.926 48.3211 55.8567 47.4262 55.1278 46.3035C54.3989 45.1831 53.8762 44.0023 53.5645 42.761C53.2504 41.5221 53.0945 40.1995 53.0945 38.7908V27.5405H48.7307V19.8233H53.0945Z"
        fill="currentColor"
      />
      <path
        d="M86.381 27.2499C84.4173 27.2499 82.7269 27.9566 81.3075 29.3698C79.8929 30.7784 79.1879 32.4706 79.1879 34.4464C79.1879 36.4687 79.8929 38.1818 81.3075 39.5881C82.7269 40.9967 84.4173 41.6987 86.381 41.6987C87.3209 41.6987 88.2128 41.5871 89.0568 41.364C89.9056 41.1362 90.5266 40.9061 90.9174 40.676L91.4449 40.3319L94.9839 47.3611C94.7082 47.547 94.3246 47.7841 93.833 48.0677C93.3391 48.3536 92.2218 48.7441 90.4763 49.2392C88.7307 49.7343 86.8534 49.9831 84.8465 49.9831C80.4084 49.9831 76.5073 48.4722 73.1457 45.4457C69.789 42.4146 68.1106 38.7862 68.1106 34.558C68.1106 30.295 69.789 26.6316 73.1457 23.568C76.5073 20.4997 80.4084 18.9656 84.8465 18.9656C86.8534 18.9656 88.702 19.1957 90.3899 19.6536C92.0779 20.1069 93.2768 20.5625 93.9865 21.0204L94.9839 21.7084L91.4449 28.6167C90.1526 27.7055 88.4646 27.2499 86.381 27.2499Z"
        fill="#0BDEDE"
      />
      <path
        d="M96.7067 19.8233V8.91699H107.132V19.8233H113.5V27.5405H107.132V36.8476C107.132 39.5509 107.918 40.9015 109.491 40.9015C109.887 40.9015 110.299 40.8271 110.728 40.6783C111.162 40.5249 111.498 40.3715 111.735 40.2227L112.09 39.9903L114.68 48.163C112.44 49.3764 109.887 49.9854 107.017 49.9854C105.053 49.9854 103.365 49.653 101.953 48.9905C100.538 48.3211 99.4688 47.4262 98.7399 46.3035C98.011 45.1831 97.4883 44.0023 97.1766 42.761C96.8625 41.5221 96.7067 40.1995 96.7067 38.7908V27.5405H92.3429V19.8233H96.7067Z"
        fill="#0BDEDE"
      />
      <path
        d="M113.905 49.1282V19.8215H124.33V24.052H124.445C124.526 23.8986 124.656 23.7056 124.828 23.4755C125.006 23.2477 125.389 22.8479 125.979 22.2761C126.567 21.7066 127.193 21.1952 127.859 20.7419C128.53 20.284 129.396 19.8749 130.458 19.5146C131.518 19.1497 132.599 18.9661 133.7 18.9661C134.836 18.9661 135.956 19.1195 137.057 19.4217C138.155 19.7262 138.96 20.0307 139.474 20.3328L140.298 20.7977L135.935 29.361C134.642 28.2964 132.837 27.7618 130.516 27.7618C129.262 27.7618 128.18 28.0291 127.274 28.5614C126.373 29.096 125.732 29.7422 125.356 30.5046C124.984 31.2671 124.718 31.9156 124.56 32.4479C124.406 32.9825 124.33 33.4009 124.33 33.7031V49.1282H113.905Z"
        fill="#0BDEDE"
      />
      <path
        d="M138.957 49.1296V0H149.382V49.1296H138.957Z"
        fill="#0BDEDE"
      />
      <path
        d="M45.899 55.8525L49.5722 64.6855H47.3472L46.6662 62.9189H43.204L42.523 64.6855H40.298L43.9712 55.8525H45.899ZM45.9661 61.1523L44.9495 58.5768H44.9207L43.9041 61.1523H45.9661Z"
        fill="currentColor"
      />
      <path
        d="M55.0283 55.8513V62.9177H58.1645V64.6842H52.9471V55.8513H55.0283Z"
        fill="currentColor"
      />
      <path
        d="M61.6202 57.6179V55.8513H68.0269V57.6179H65.8689V64.6842H63.7878V57.6179H61.6202Z"
        fill="currentColor"
      />
      <path
        d="M77.0044 55.8525V57.6191H73.8682V59.3764H76.8701V61.143H73.8682V62.9189H77.0044V64.6855H71.787V55.8525H77.0044Z"
        fill="currentColor"
      />
      <path
        d="M81.2178 55.8525H84.4786C85.3538 55.8525 86.0659 56.0803 86.6174 56.5313C87.1736 56.9845 87.4518 57.6331 87.4518 58.4745C87.4518 59.1254 87.2815 59.6716 86.9435 60.1109C86.6102 60.5456 86.157 60.8524 85.5816 61.0314L88.7178 64.6855H86.0803L83.299 61.1895V64.6855H81.2178V55.8525ZM83.299 59.7669H83.5387C83.7306 59.7669 83.8888 59.7646 84.0183 59.7576C84.1525 59.753 84.3012 59.7274 84.469 59.6832C84.6417 59.6414 84.7783 59.581 84.8814 59.5066C84.9893 59.4275 85.0781 59.3113 85.15 59.1626C85.2267 59.0091 85.2651 58.8255 85.2651 58.614C85.2651 58.3978 85.2267 58.2142 85.15 58.0654C85.0781 57.9167 84.9893 57.8051 84.8814 57.7307C84.7783 57.6517 84.6417 57.5889 84.469 57.5447C84.3012 57.5029 84.1525 57.4773 84.0183 57.4704C83.8888 57.4587 83.7306 57.4518 83.5387 57.4518H83.299V59.7669Z"
        fill="currentColor"
      />
      <path
        d="M94.2091 59.1997V64.6855H92.1279V55.8525H94.2091L97.8344 61.3289H97.8536V55.8525H99.9252V64.6855H97.8536L94.2283 59.1997H94.2091Z"
        fill="currentColor"
      />
      <path
        d="M108.9 55.8525L112.573 64.6855H110.348L109.667 62.9189H106.205L105.524 64.6855H103.299L106.972 55.8525H108.9ZM108.967 61.1523L107.95 58.5768H107.922L106.905 61.1523H108.967Z"
        fill="currentColor"
      />
      <path
        d="M115.358 57.6191V55.8525H121.765V57.6191H119.607V64.6855H117.526V57.6191H115.358Z"
        fill="currentColor"
      />
      <path
        d="M127.605 55.8525V64.6855H125.524V55.8525H127.605Z"
        fill="currentColor"
      />
      <path
        d="M134.787 64.6855L130.97 55.8525H133.31L135.612 61.6544L137.904 55.8525H140.244L136.427 64.6855H134.787Z"
        fill="currentColor"
      />
      <path
        d="M148.837 55.8525V57.6191H145.701V59.3764H148.703V61.143H145.701V62.9189H148.837V64.6855H143.62V55.8525H148.837Z"
        fill="currentColor"
      />
      <path
        d="M79.3914 74.0192C79.7031 74.0192 80.01 73.9727 80.3121 73.8797C80.6119 73.7868 80.8324 73.6984 80.9739 73.6101L81.1753 73.4706L82.0673 75.1907C82.0337 75.2093 81.9929 75.2372 81.9426 75.2744C81.8898 75.3116 81.7699 75.3813 81.5781 75.479C81.3911 75.5719 81.1897 75.6556 80.9739 75.73C80.7629 75.7997 80.4848 75.8602 80.1395 75.916C79.7942 75.9718 79.4346 75.9996 79.0653 75.9996C78.2214 75.9996 77.4181 75.7997 76.6581 75.3953C75.9028 74.9932 75.2914 74.4376 74.8262 73.731C74.3587 73.0244 74.1261 72.2619 74.1261 71.4437C74.1261 70.8184 74.2628 70.2211 74.5385 69.6492C74.8118 69.0798 75.1739 68.5939 75.6223 68.1895C76.0754 67.7804 76.6029 67.455 77.2047 67.2132C77.8114 66.9668 78.4324 66.8413 79.0653 66.8413C79.66 66.8413 80.2018 66.911 80.6958 67.0459C81.1945 67.183 81.5446 67.3201 81.7508 67.455L82.0673 67.6595L81.1753 69.3703C81.1226 69.3285 81.0458 69.2773 80.9451 69.2215C80.842 69.1611 80.6334 69.0798 80.3217 68.9798C80.0148 68.8752 79.7031 68.8217 79.3914 68.8217C78.8927 68.8217 78.4419 68.9031 78.0391 69.0635C77.6411 69.2192 77.3294 69.4261 77.0992 69.6864C76.8691 69.9421 76.6916 70.2164 76.5717 70.5139C76.4495 70.8115 76.3895 71.1137 76.3895 71.4158C76.3895 72.0922 76.6485 72.6966 77.1664 73.2289C77.6843 73.7566 78.4252 74.0192 79.3914 74.0192Z"
        fill="currentColor"
      />
      <path
        d="M85.4672 71.4166C85.4672 70.1404 85.9179 69.0596 86.8195 68.1716C87.721 67.286 88.8048 66.842 90.0707 66.842C91.3415 66.842 92.4229 67.286 93.3124 68.1716C94.2067 69.0526 94.6551 70.1335 94.6551 71.4166C94.6551 72.7136 94.2163 73.8014 93.3412 74.6801C92.4636 75.5611 91.3751 76.0004 90.0707 76.0004C88.7328 76.0004 87.6299 75.5634 86.7619 74.6894C85.8987 73.8154 85.4672 72.7252 85.4672 71.4166ZM87.7114 71.4166C87.7114 71.8815 87.7833 72.3068 87.932 72.6904C88.0782 73.0692 88.3372 73.3923 88.7088 73.6573C89.0781 73.9246 89.5336 74.0571 90.0707 74.0571C90.8428 74.0571 91.4254 73.8014 91.8163 73.2854C92.2119 72.7717 92.4109 72.1488 92.4109 71.4166C92.4109 70.6983 92.2095 70.0823 91.8067 69.5663C91.4038 69.0456 90.8236 68.7853 90.0707 68.7853C89.3155 68.7853 88.7328 69.0456 88.3252 69.5663C87.9152 70.0823 87.7114 70.6983 87.7114 71.4166Z"
        fill="currentColor"
      />
      <path
        d="M100.646 70.3567V75.8424H98.5643V67.0095H100.646L104.271 72.4859H104.29V67.0095H106.362V75.8424H104.29L100.665 70.3567H100.646Z"
        fill="currentColor"
      />
      <path
        d="M110.135 68.7761V67.0095H116.541V68.7761H114.383V75.8424H112.302V68.7761H110.135Z"
        fill="currentColor"
      />
      <path
        d="M120.294 67.0093H123.555C124.43 67.0093 125.142 67.2371 125.693 67.688C126.25 68.1413 126.528 68.7898 126.528 69.6313C126.528 70.2821 126.357 70.8284 126.019 71.2677C125.686 71.7023 125.233 72.0092 124.658 72.1882L127.794 75.8422H125.156L122.375 72.3462V75.8422H120.294V67.0093ZM122.375 70.9237H122.615C122.806 70.9237 122.965 70.9213 123.094 70.9144C123.228 70.9097 123.377 70.8841 123.545 70.84C123.718 70.7981 123.854 70.7377 123.957 70.6633C124.065 70.5843 124.154 70.4681 124.226 70.3193C124.303 70.1659 124.341 69.9823 124.341 69.7707C124.341 69.5546 124.303 69.3709 124.226 69.2222C124.154 69.0734 124.065 68.9618 123.957 68.8874C123.854 68.8084 123.718 68.7456 123.545 68.7015C123.377 68.6596 123.228 68.6341 123.094 68.6271C122.965 68.6155 122.806 68.6085 122.615 68.6085H122.375V70.9237Z"
        fill="currentColor"
      />
      <path
        d="M130.795 71.4166C130.795 70.1404 131.245 69.0596 132.147 68.1716C133.049 67.286 134.132 66.842 135.398 66.842C136.669 66.842 137.75 67.286 138.64 68.1716C139.534 69.0526 139.983 70.1335 139.983 71.4166C139.983 72.7136 139.544 73.8014 138.669 74.6801C137.791 75.5611 136.703 76.0004 135.398 76.0004C134.06 76.0004 132.957 75.5634 132.089 74.6894C131.226 73.8154 130.795 72.7252 130.795 71.4166ZM133.039 71.4166C133.039 71.8815 133.111 72.3068 133.26 72.6904C133.406 73.0692 133.665 73.3923 134.036 73.6573C134.406 73.9246 134.861 74.0571 135.398 74.0571C136.17 74.0571 136.753 73.8014 137.144 73.2854C137.539 72.7717 137.738 72.1488 137.738 71.4166C137.738 70.6983 137.537 70.0823 137.134 69.5663C136.731 69.0456 136.151 68.7853 135.398 68.7853C134.643 68.7853 134.06 69.0456 133.653 69.5663C133.243 70.0823 133.039 70.6983 133.039 71.4166Z"
        fill="currentColor"
      />
      <path
        d="M145.99 67.0095V74.0759H149.127V75.8424H143.909V67.0095H145.99Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default MainLogo;