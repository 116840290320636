import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  events: null,
  status: "idle",
  error: null,
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCalendar.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCalendar.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.events = action.payload;
      })
      .addCase(getCalendar.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { fetch_calendar } = calendarSlice.actions;

export default calendarSlice.reducer;

export const currentCalendar = (state) => state.calendar;

export const getCalendar = createAsyncThunk(
  "calendar/getCalendar",
  async () => {
    const response = await axios.get("/api/calendar");

    console.log("calendar slice: ", response);
    return response.data;
  }
);
