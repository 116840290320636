import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";

import loader from "../loading.svg";

const EnterpriseSetting = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const [isPopup, setIsPopup] = useState(false);
  const [isLoading, setIsLoading] = useState("block");
  const [popupText, setPopupText] = useState("");
  const [smallPopup, setSmallPopup] = useState(false);
  const [email, setEmail] = useState("profile");
  const [position, setPosition] = useState("profile");
  const [username, setUsername] = useState("profile");
  const [whatsappnumberid, setWhatsappnumberid] = useState("profile");
  const [subusers, setSubusers] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [isPause, setIsPause] = useState(false);
  const [isRestart, setIsRestart] = useState(false);
  const [accountHelper, setAccountHelper] = useState(false);

  useEffect(() => {
    const getSubusers = async function () {
      try {
        const response = await axios.get("api/subusers", {
          withCredentials: true,
        });
        console.log("sub-users: ", response.data);
        setSubusers(response.data);
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          alert("You're logged out. Please log in again.");
        }
      }
    };
    getSubusers();
  }, []);

  console.log("state: ", subusers);

  const handlePauseClick = () => {
    setIsPause(true);
  };

  const handleRestartClick = () => {
    setIsRestart(true);
  };

  const handlePauseClose = (event) => {
    if (event.target.classList.contains("pause-popup")) {
      setIsPause(false);
    }
  };

  const handleNewUserClick = (event) => {
    setIsPopup(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSmallPopup(true);
    console.log(username, email, whatsappnumberid);

    let data = {
      email: email,
      username: username,
      position: position,
      whatsappnumberid: whatsappnumberid,
    };
    console.log("agent data:", data);
    try {
      const response = await axios.post("api/add-subuser", data, {
        withCredentials: true,
      });
      console.log("success: ", response);
      setIsLoading("none");
      setPopupText("Success");
      setTimeout(() => {
        setSmallPopup(false);
        setIsPopup(false);
        setPopupText("");
        setIsLoading("block");
      }, 3000);
    } catch (error) {
      console.log("error: ", error);
      if (error.response.data.message == "max_users_reached") {
        setPopupText("Max number of subusers reached.");
      } else {
        setPopupText("Error");
      }
      setIsLoading("none");
      setTimeout(() => {
        setSmallPopup(false);
        setIsPopup(false);
        setPopupText("");
        setIsLoading("block");
      }, 3000);
    }
  };

  const handlePause = async () => {
    setSmallPopup(true);
    console.log("agent id: ", accountHelper);

    let data = {
      accountStatus: "pause",
      id: accountHelper,
    };

    try {
      const response = await axios.post("api/pause-subuser", data, {
        withCredentials: true,
      });

      console.log("pause res: ", response);
      setIsLoading("none");
      setPopupText("Success");
      setSubusers(response.data);
      setTimeout(() => {
        setSmallPopup(false);
        setIsPause(false);
        setPopupText("");
        setIsLoading("block");
      }, 3000);
    } catch (error) {
      console.log(error);

      if (error.response.data.message == "max_users_reached") {
        setPopupText("Max number of subusers reached.");
      } else {
        setPopupText("Error");
      }
      setIsLoading("none");
      setTimeout(() => {
        setSmallPopup(false);
        setIsPause(false);
        setPopupText("");
        setIsLoading("block");
      }, 3000);
    }

    // setIsPause(false)
  };

  const handleRestart = async () => {
    setSmallPopup(true);
    console.log("agent id: ", accountHelper);

    let data = {
      accountStatus: "restart",
      id: accountHelper,
    };

    try {
      const response = await axios.post("api/pause-subuser", data, {
        withCredentials: true,
      });

      console.log("restart res: ", response);
      setIsLoading("none");
      setPopupText("Success");
      setSubusers(response.data);
      setTimeout(() => {
        setSmallPopup(false);
        setIsRestart(false);
        setPopupText("");
        setIsLoading("block");
      }, 3000);
    } catch (error) {
      console.log(error);

      if (error.response.data.message == "max_users_reached") {
        setPopupText("Max number of subusers reached.");
      } else {
        setPopupText("Error");
      }
      setIsLoading("none");
      setTimeout(() => {
        setSmallPopup(false);
        setIsRestart(false);
        setPopupText("");
        setIsLoading("block");
      }, 3000);
    }

    // setIsPause(false)
  };

  return (
    <main className="flex setting-page">
      <Sidebar title="Setting" />
      <div className="main-container">
        <Header />
        <div className="dashboard">
          <div className="tabs flex">
            <div className="tab_links">
              <button
                className={`tab_link ${
                  activeTab === "profile" ? "active" : ""
                }`}
                onClick={() => handleTabClick("profile")}
                data-tab="profile"
              >
                PROFILE
              </button>
              <button
                className={`tab_link ${
                  activeTab === "account" ? "active" : ""
                }`}
                onClick={() => handleTabClick("account")}
                data-tab="account"
              >
                ACCOUNT
              </button>
              <button
                className={`tab_link ${activeTab === "users" ? "active" : ""}`}
                onClick={() => handleTabClick("users")}
                data-tab="users"
              >
                USERS
              </button>
              <button
                className={`tab_link ${
                  activeTab === "feedback_complaint" ? "active" : ""
                }`}
                onClick={() => handleTabClick("feedback_complaint")}
                data-tab="feedback_complaint"
              >
                FEEDBACK & COMPLAINTS
              </button>
              <a href="#" className="btn dashboard__btn">
                UPGRADE
              </a>
            </div>
            <div className="tabs_content">
              <div
                className={`profile ${activeTab === "profile" ? "active" : ""}`}
              >
                <div className="user_img">
                  <img
                    src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=1505"
                    alt="user"
                  />
                </div>
                <a href="#" className="btn dashboard__btn inline-block">
                  Change Profile
                </a>
                <form action="#" className="setting_form">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Full Name"
                      value="Adison Culhane"
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Designation"
                      value="Senior Property Consultant"
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Email"
                      value="a.culhane@gmail.com"
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Whatsapp Business Connect"
                      value="+971-55-2233445"
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Company Name"
                      value="Company Name (Optional)"
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <button className="btn dashboard__btn inline-block">
                      Submit
                    </button>
                    <button className="btn dashboard__btn_white inline-block">
                      {" "}
                      Cancel
                    </button>
                  </div>
                </form>
              </div>

              <div
                className={`account ${activeTab === "account" ? "active" : ""}`}
              >
                <form action="#" className="setting_form">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="English"
                      value="Trade_License.pdf"
                    />
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="English"
                      value="National_ID.pdf"
                    />
                  </div>

                  <div className="input-group">
                    <select>
                      <option>Language</option>
                      <option selected>English</option>
                      <option>French</option>
                    </select>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="**********"
                      value="**********"
                    />
                    <img src="assets/icons/icon-edit.svg" alt="edit" />
                  </div>

                  <div className="input-group">
                    <a href="#" className="btn dashboard__btn inline-block">
                      Submit
                    </a>
                    <a
                      href="#"
                      className="btn dashboard__btn_white inline-block"
                    >
                      Cancel
                    </a>
                  </div>
                </form>
              </div>

              <div className={`users ${activeTab === "users" ? "active" : ""}`}>
                <div class="user_list">
                  {subusers
                    ? subusers.map((agent) => {
                        return (
                          <div
                            key={agent._id}
                            className={
                              agent.active ? "user_card" : "user_card paused"
                            }
                          >
                            <h2>{agent.displayname}</h2>
                            <p class="mb-20">{agent.position}</p>
                            <p>
                              <span>Whatsapp ID: </span> &nbsp;{" "}
                              {agent.whatsappnumberid}
                            </p>
                            <p className="mt-10">
                              <span>Email: </span> {agent.username}
                            </p>

                            {agent.active ? (
                              ""
                            ) : (
                              <p className="account-warning">ACCOUNT PAUSED</p>
                            )}

                            <div class="btns mt-40">
                              <button>
                                <div className="btns-icon">
                                  <img
                                    src="assets/icons/icon-edit-setting.svg"
                                    alt="edit"
                                  />
                                </div>
                                Edit
                              </button>

                              <button>
                                <div className="btns-icon">
                                  <img
                                    src="assets/icons/icon-usage.svg"
                                    alt="edit"
                                  />
                                </div>
                                Usage
                              </button>
                              {agent.active ? (
                                <button
                                  onClick={() => {
                                    setAccountHelper(agent._id);
                                    handlePauseClick();
                                  }}
                                >
                                  <div className="btns-icon">
                                    <img
                                      src="assets/icons/icon-pause.svg"
                                      alt="edit"
                                    />
                                  </div>
                                  Pause
                                </button>
                              ) : (
                                <button
                                  className="restart-btn"
                                  onClick={() => {
                                    setAccountHelper(agent._id);
                                    handleRestartClick();
                                  }}
                                >
                                  <div className="btns-icon">
                                    <img
                                      src="assets/icons/icon-restart.svg"
                                      alt="edit"
                                    />
                                  </div>
                                  Restart
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })
                    : null}

                  {/* <div class="user_card paused">
                    <h2>Rayna Baptista</h2>
                    <p class="mb-20">Property Consultant</p>
                    <p>
                      <span>Phone: </span> &nbsp; +971 - 50 - 1122334
                    </p>
                    <p className="mt-10">
                      <span>Email: </span> C.Stanton@gmail.com
                    </p>

                    <p className="account-warning">ACCOUNT PAUSED</p>

                    <div class="btns mt-40">
                      <button>
                        <div className="btns-icon">
                          <img
                            src="assets/icons/icon-edit-setting.svg"
                            alt="edit"
                          />
                        </div>
                        Edit
                      </button>

                      <button>
                        <div className="btns-icon">
                          <img src="assets/icons/icon-usage.svg" alt="edit" />
                        </div>
                        Usage
                      </button>
                      <button
                        className="restart-btn"
                        onClick={handleRestartClick}
                      >
                        <div className="btns-icon">
                          <img src="assets/icons/icon-restart.svg" alt="edit" />
                        </div>
                        Restart
                      </button>
                    </div>
                  </div> */}

                  {/* Add User Button */}
                  <div
                    class="user_card add_item_card flex flex-direction-column flex-center"
                    onClick={handleNewUserClick}
                  >
                    <a href="#" class="flex-center flex-direction-column">
                      <img src="assets/icons/icon-plus.svg" />
                      ADD USER
                    </a>
                  </div>

                  {/* Add User Form */}
                  {isPopup && (
                    <div className="leads-popup leads-popup--delete add-event-container">
                      <div className="leads-popup__container flex justify-content-center align-center add-event-popup">
                        <h3 style={{ paddingBottom: "20px" }}>Add Agent</h3>
                        <form
                          onSubmit={handleSubmit}
                          className="flex justify-content-center align-center add-event-popup"
                        >
                          <input
                            required
                            type="text"
                            placeholder="Name"
                            className="mb-20"
                            autoComplete="none"
                            onChange={(e) => setUsername(e.target.value)}
                          />
                          <input
                            required
                            type="email"
                            placeholder="Email"
                            className="mb-20"
                            name="title"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <input
                            required
                            type="text"
                            placeholder="Position"
                            className="mb-20"
                            name="title"
                            onChange={(e) => setPosition(e.target.value)}
                          />
                          <input
                            required
                            type="text"
                            placeholder="WhatsApp Number ID"
                            className="mb-20"
                            name="title"
                            onChange={(e) =>
                              setWhatsappnumberid(e.target.value)
                            }
                          />
                          <div className="flex align-center broad-btns">
                            <button
                              type="submit"
                              className="btn btn--schedule flex align-center"
                              onClick={(e) => {
                                setIsPopup(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button type="submit" className="btn btn--lunch">
                              Invite Agent
                            </button>
                          </div>
                        </form>
                      </div>
                      {smallPopup && (
                        <div className="leads-popup leads-popup--delete">
                          <div className="leads-popup__container flex justify-content-center align-center">
                            <img
                              src={loader}
                              style={{ height: "150px", display: isLoading }}
                            />
                            <h3
                              style={{
                                color: popupText == "Success" ? "green" : "red",
                              }}
                            >
                              {popupText}
                            </h3>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`feedback_complaint ${
                  activeTab === "feedback_complaint" ? "active" : ""
                }`}
              >
                <form action="#" className="setting_form">
                  <textarea
                    name="feedback_complaint"
                    id="feedback_complaint"
                    defaultValue={""}
                  />

                  <div className="flex setting-checkboxes">
                    <div>
                      <input type="radio" id="feedback" name="reason" />
                      <label htmlFor="feedback">Feedback</label>
                    </div>

                    <div>
                      <input type="radio" id="feedback" name="reason" />
                      <label htmlFor="feedback">Complaint</label>
                    </div>
                  </div>
                  <div className="input-group">
                    <a href="#" className="btn dashboard__btn inline-block">
                      Submit
                    </a>
                    <a
                      href="#"
                      className="btn dashboard__btn_white inline-block"
                    >
                      Cancel
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPause && (
        <div className="pause-popup" onClick={handlePauseClose}>
          <div className="pause-popup__container">
            Pause Account?
            <div className="btns flex justify-content-center gap-20">
              <a
                href="#"
                className="btn dashboard__btn inline-block"
                onClick={handlePause}
              >
                Pause
              </a>
              <a
                href="#"
                className="btn inline-block dashboard__btn_white"
                onClick={() => {
                  setAccountHelper(false);
                  setIsPause(false);
                }}
              >
                Cancel
              </a>
            </div>
          </div>
          {smallPopup && (
            <div className="leads-popup leads-popup--delete">
              <div className="leads-popup__container flex justify-content-center align-center">
                <img
                  src={loader}
                  style={{ height: "150px", display: isLoading }}
                />
                <h3
                  style={{
                    color: popupText == "Success" ? "green" : "red",
                  }}
                >
                  {popupText}
                </h3>
              </div>
            </div>
          )}
        </div>
      )}

      {isRestart && (
        <div className="pause-popup" onClick={handlePauseClose}>
          <div className="pause-popup__container">
            Restart Account?
            <div className="btns flex justify-content-center gap-20">
              <a
                href="#"
                className="btn dashboard__btn inline-block"
                onClick={handleRestart}
              >
                Restart
              </a>
              <a
                href="#"
                className="btn inline-block dashboard__btn_white"
                onClick={() => {
                  setAccountHelper(false);
                  setIsRestart(false);
                }}
              >
                Cancel
              </a>
            </div>
          </div>
          {smallPopup && (
            <div className="leads-popup leads-popup--delete">
              <div className="leads-popup__container flex justify-content-center align-center">
                <img
                  src={loader}
                  style={{ height: "150px", display: isLoading }}
                />
                <h3
                  style={{
                    color: popupText == "Success" ? "green" : "red",
                  }}
                >
                  {popupText}
                </h3>
              </div>
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default EnterpriseSetting;
