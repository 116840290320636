import React from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";

const Chat = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");

  return (
    <main className="flex">
      <Sidebar title="Broad" />
      <div className="main-container">
        <Header />
      
        <div className="search-result">
          <h3>Search: <span>{ query }</span></h3>

          <div className="search-result__wrapper flex justify-content-between">
            <div className="flex gap-200">
              <div className="search-result__wrapper-col flex flex-direction-column gap-11">
                <div className="flex gap-24">
                  <div className="search-result__property">Name:</div>
                  <div className="search-result__value">Abram Bergson</div>
                </div>

                <div className="flex gap-24">
                  <div className="search-result__property">Level:</div>
                  <div className="search-result__value">Abram Bergson</div>
                </div>

                <div className="flex gap-24">
                  <div className="search-result__property">Budget:</div>
                  <div className="search-result__value">AED 8,500,0000.00</div>
                </div>

                <div className="flex gap-24">
                  <div className="search-result__property">Property:</div>
                  <div className="search-result__value">Beachfront Villas</div>
                </div>

                <div className="flex gap-24">
                  <div className="search-result__property">Status:</div>
                  <div className="search-result__value">Meeting Request</div>
                </div>
              </div>
              
              <div className="search-result__wrapper-col flex flex-direction-column gap-11">
                <div className="flex gap-24">
                  <div className="search-result__property">Mobile Number:</div>
                  <div className="search-result__value">+971-50-1122334</div>
                </div>

                <div className="flex gap-24">
                  <div className="search-result__property">Email Address:</div>
                  <div className="search-result__value">Abergon@gmail.com</div>
                </div>

                <div className="flex gap-24">
                  <div className="search-result__property">Source:</div>
                  <div className="search-result__value">Whatsapp Broadcast</div>
                </div>

                <div className="flex gap-24">
                  <div className="search-result__property">Fund Source:</div>
                  <div className="search-result__value">Mortgage</div>
                </div>
              </div>
            </div>

            <div className="flex flex-direction-column gap-11 align-end">
              <button className="flex align-center gap-5">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.25 2.58333C10.8333 2.58333 10.4167 2.5 10 2.5C9.58333 2.5 9.16667 2.58333 8.75 2.58333" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M16.0835 5.66663C15.5864 5.00295 14.9972 4.41367 14.3335 3.91663" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M17.4165 11.25C17.4998 10.8333 17.4998 10.4167 17.4998 10C17.4998 9.58333 17.4165 9.16667 17.4165 8.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M14.3335 16.0834C14.9972 15.5863 15.5864 14.997 16.0835 14.3334" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M8.75 17.4166C9.16667 17.5 9.58333 17.5 10 17.5C10.4167 17.5 10.8333 17.4166 11.25 17.4166" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M2.9165 14.5834L1.6665 18.3334L5.4165 17.0834" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M2.58333 8.75C2.58333 9.16667 2.5 9.58333 2.5 10C2.5 10.4167 2.58333 10.8333 2.58333 11.25" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M5.6665 3.91663C5.00283 4.41367 4.41355 5.00295 3.9165 5.66663" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                View Conversation
              </button>

              <button className="flex align-center gap-5">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.625 1.87503C10.7842 1.68693 10.9811 1.53382 11.2031 1.42546C11.4252 1.3171 11.6676 1.25587 11.9148 1.24567C12.162 1.23547 12.4086 1.27652 12.639 1.36621C12.8693 1.4559 13.0784 1.59227 13.2527 1.76661C13.427 1.94095 13.5629 2.14945 13.6516 2.37881C13.7403 2.60817 13.7799 2.85337 13.7679 3.09877C13.7559 3.34417 13.6925 3.5844 13.5818 3.80414C13.4711 4.02387 13.3155 4.2183 13.125 4.37503L4.6875 12.8125L1.25 13.75L2.1875 10.3125L10.625 1.87503Z" stroke="#9A9A9A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                Edit Details
              </button>

              <button className="flex align-center gap-5">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 4H14" stroke="#9A9A9A" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12.6668 4V13.3333C12.6668 14 12.0002 14.6667 11.3335 14.6667H4.66683C4.00016 14.6667 3.3335 14 3.3335 13.3333V4" stroke="#9A9A9A" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M5.3335 4.00004V2.66671C5.3335 2.00004 6.00016 1.33337 6.66683 1.33337H9.3335C10.0002 1.33337 10.6668 2.00004 10.6668 2.66671V4.00004" stroke="#9A9A9A" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M6.6665 7.33337V11.3334" stroke="#9A9A9A" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M9.3335 7.33337V11.3334" stroke="#9A9A9A" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Chat;
