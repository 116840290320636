import React from "react";

const Payment1 = () => {
  return (
    <div className="flex options">
      <a href="#" className="page__logo">
        <img src="assets/images/logo-medium.svg" alt srcSet />
      </a>
      <div className="payment-container color-white">
        <h2>Document Upload</h2>
        <form>
          <input type="file" name id="file-1" />
          <label htmlFor="file-1" className="file-label">
            Upload Company Trade License
          </label>
          <input type="file" name id="file-2" />
          <label htmlFor="file-2" className="file-label">
            Upload Identity Card
          </label>
          <div className="terms">
            <input type="checkbox" name id="terms" />
            <label htmlFor="terms">
              Acceptance of <a href="#">Terms and Conditions</a> and{" "}
              <a href="#">Privacy Policy</a>{" "}
            </label>
          </div>
          <button type="submit" className="btn btn--started btn--submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Payment1;
