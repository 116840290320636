import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slices/authSlice";
import listingsSlice from "../slices/listingsSlice";
import conversationsSlice from "../slices/conversationsSlice";
import calendarSlice from "../slices/calendarSlice";

export default configureStore({
  reducer: {
    auth: authSlice,
    listings: listingsSlice,
    conversations: conversationsSlice,
    calendar: calendarSlice,
  },
});
