const IconTeam = () => {
  return (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 21V19C23 17.9391 22.5786 16.9217 21.8284 16.1716C21.0783 15.4214 20.0609 15 19 15H13C11.9391 15 10.9217 15.4214 10.1716 16.1716C9.42143 16.9217 9 17.9391 9 19V21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 11C18.2091 11 20 9.20914 20 7C20 4.79086 18.2091 3 16 3C13.7909 3 12 4.79086 12 7C12 9.20914 13.7909 11 16 11Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3 21V18.9557C3.00066 18.0498 3.29564 17.1698 3.83863 16.4538C4.38162 15.7378 5.14187 15.2265 6 15" stroke="#0BDEDE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.19556 10.8018C8.3254 10.6238 7.53923 10.1613 6.96098 9.48712C6.38272 8.81298 6.04529 7.96555 6.00187 7.07844C5.95845 6.19133 6.21151 5.315 6.72116 4.58761C7.23081 3.86021 7.96805 3.32313 8.81667 3.06103" stroke="#0BDEDE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
}

export default IconTeam;