import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import MainLogo from "./svgs/MainLogo";
import IconDashboard from "./svgs/IconDashboard";
import IconLeads from "./svgs/IconLeads";
import IconBroad from "./svgs/IconBroad";
import IconCalender from "./svgs/IconCalender";
import IconConversion from "./svgs/IconConversion";
import IconAnalytics from "./svgs/IconAnalytics";
import IconPipline from "./svgs/IconPipline";
import IconLight from "./svgs/IconLight";
import IconDark from "./svgs/IconDark";
import IconSetting from "./svgs/IconSetting";
import IconLogout from "./svgs/IconLogout";
import IconTeam from "./svgs/IconTeam";

const Sidebar = ({ title }) => {
  const location = useLocation();

  const [isLightMode, setIsLightMode] = useState(false);

  function changeState() {
    setIsLightMode(!isLightMode);
    toggleTheme();
  }

  const toggleTheme = () => {
    const hasSidebar = document.querySelector(".sidebar") !== null;

    if (hasSidebar) {
      document.body.setAttribute("data-theme", isLightMode ? "dark" : "light");
    }
  };

  useEffect(() => {
    document.title = title ? title : "Dashboard";
  }, [location]);

  return (
    <aside className="sidebar flex flex-direction-column align-center">
      <NavLink activeclassname="active" className={"brand"} to="/">
        <MainLogo />
      </NavLink>

      <ul
        className="sidebar-navigation flex flex-direction-column"
        role="navigation"
      >
        <li>
          <NavLink to="/" aria-hidden="true">
            <IconDashboard />
            Dashboard
          </NavLink>
        </li>

        <li>
          <NavLink activeclassname="active" to="/team">
            <IconTeam />
            Team
          </NavLink>
        </li>

        <li>
          <NavLink activeclassname="active" to="/calendar">
            <IconCalender />
            Calendar
          </NavLink>
        </li>

        <li>
          <NavLink activeclassname="active" to="/analytics">
            <IconAnalytics />
            Analytics
          </NavLink>
        </li>
      </ul>

      <ul className="sidebar-secondary-navigation flex flex-direction-column">
        <li className="theme-switch flex justify-content-center">
          <button
            className="theme-switch--dark active flex-center"
            aria-label="to dark mode"
            onClick={changeState}
          >
            <IconLight />
          </button>

          <button
            className="theme-switch--light flex-center"
            aria-label="to light mode"
            onClick={changeState}
          >
            <IconDark />
          </button>
        </li>

        <li>
          <NavLink activeclassname="active" to="/settings">
            <IconSetting />
            Settings
          </NavLink>
        </li>

        <li>
          <a href="/api/logout">
            <IconLogout />
            Log Out
          </a>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
