import React, { useState } from "react";

const TodoList = () => {
  const [todos, setTodos] = useState([
    { text: "Send Boulevard Villas Pictures to James", time: "12:00 PM" },
    { text: "Pick up groceries", time: "5:00 PM" }, // Added a dummy task
  ]);
  const [isAddingTodo, setIsAddingTodo] = useState(false);
  const [newTodo, setNewTodo] = useState("");

  const handleInputChange = (event) => {
    setNewTodo(event.target.value);
  };

  const addTodo = () => {
    if (newTodo.trim()) {
      // Check for empty input before adding
      setTodos([
        ...todos,
        { text: newTodo, time: new Date().toLocaleTimeString() },
      ]);
      setNewTodo("");
      setIsAddingTodo(false); // Hide input field after adding
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      addTodo();
    }
  };

  const toggleAddTodo = () => {
    setIsAddingTodo(!isAddingTodo);
  };

  const handleCheckboxToggle = (index) => {
    // Add a delay before removing the todo item
    setTimeout(() => {
      const updatedTodos = [...todos];
      updatedTodos.splice(index, 1); // Remove the todo at the given index
      setTodos(updatedTodos);
    }, 300); // Adjust the delay time (in milliseconds) as needed for a smoother animation
  };

  return (
    <div className="todo-list">
      <div className="dashboard__label">To-do List</div>
      {todos.map((todo, index) => (
        <div
          key={todo.text}
          className="todo-list__todo flex justify-content-between"
        >
          <div className="flex align-center">
            <input
              type="checkbox"
              className="checkbox"
              onChange={() => handleCheckboxToggle(index)}
            />
            <div>
              <h3>{todo.text}</h3>
              <p>{todo.time}</p>
            </div>
          </div>
        </div>
      ))}
      <div className="todo-list__todo flex justify-content-between">
        {isAddingTodo ? (
          <input
            type="text"
            placeholder="Add New Task"
            value={newTodo}
            onChange={handleInputChange}
            onKeyDown={handleEnterPress}
            style={{
              width: "100%",
              color: "#fff",
              background: "transparent",
              outline: "0",
              padding: "8px",
              border: "1px solid gray",
              fontSize: "16",
            }}
            onBlur={() => setIsAddingTodo(false)} // Hide on blur (click outside)
          />
        ) : (
          <div className="add-new-task-box" onClick={toggleAddTodo}>
            <h3>+ Add New Task</h3>
          </div>
        )}
        {/* <button onClick={addTodo} disabled={!isAddingTodo}>
          Add
        </button>{" "} */}
      </div>
    </div>
  );
};

export default TodoList;
